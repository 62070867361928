import React, {useEffect, useState} from 'react';
import {checkData} from "../../helper";
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import axios from "axios";
import {baseURL} from "../../utils/constant";

function Podcastlisting(porps) {
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        const response = await axios.get(`${baseURL}/podcast-listing?status=1&limit=8`);
        setUserData(response.data.data);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);
    return (
        <div className="podcast_listing tekrevol_gray_bg p-100">
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={10} xl={10} xxl={10} className="mb-5">
                        <div className="example" data-text="Podcast Ep">
                            <h2>Latest Episodes</h2>
                        </div>
                        <p>We collaborate with entrepreneurs and industry leaders to emphasize the importance of digital
                            transformation. We regularly invite technopreneurs and change leaders to talk about how
                            technology played a crucial role in transforming their businesses and how it helps them
                            scale up with sustainability.</p>
                    </Col>
                </Row>
                <Row>
                    {userData && userData ? userData.map((value, i) => (
                        <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                            <div className="podcast_list">
                                <h4>{checkData(value, 'title')}</h4>
                                <div
                                    dangerouslySetInnerHTML={{__html: `${value.short_description.substring(0, 180)}...`}}
                                    className="podcast_txt"/>
                                <Link to={`/podcastdetail/${checkData(value, 'slug')}`}
                                      className="btn_podcast"> Listen to this Episode
                                </Link>
                            </div>
                        </Col>
                    )) : ''}
                </Row>
            </Container>
        </div>
    );

}


export default Podcastlisting;